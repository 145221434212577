

















































































































































































import {
  createAchievement,
  getAllAchievementSuggestions,
} from "@/api/achievement-service";
import AchievementCard from "@/components/achievements/AchievementCard.vue";
import AchievementPhotoUploader from "@/components/achievements/AchievementPhotoUploader.vue";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import { stringTimeToUnix } from "@/utils/timeUtils";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "CreateAchievement",
  components: { AchievementCard, AchievementPhotoUploader },
  props: {
    suggestion: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      achievement: {
        achievedDescription: "",
        achievementType: "persistent",
        easterEgg: false,
        exp: 0,
        draft: false,
        groupID: "",
        level: 1,
        lockedDescription: "",
        manualAssignment: true,
        title: "",
      } as Learnlink.Achievement.Create,
      achievementSuggestions: [] as Learnlink.Achievement.default[],
      challenge: false,
      challengeEndTime: "",
      currentAchievement: {} as Learnlink.Achievement.default,
      loading: false,
      mountingSuggestions: false,
    };
  },
  async mounted() {
    await this.mountSuggestions();
  },
  computed: {
    formValidation: function(): boolean {
      return !!this.achievement.achievedDescription &&
        !!this.achievement.lockedDescription &&
        !!this.achievement.title &&
        (!this.challenge || !!this.challengeEndTime);
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      this.achievement.groupID = this.achievement.title;
      if (this.challenge) {
        this.achievement.achievementType = "challenge";
        this.achievement.challengeEndTime = stringTimeToUnix(this.challengeEndTime, "23.59");
      }
      try {
        if (this.suggestion) {
          this.achievement.draft = true;
          await createAchievement(this.achievement);
        }
        else {
          this.currentAchievement = await createAchievement(this.achievement);
        }
        await this.mountSuggestions();
        this.$emit("success");
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Utmerkelse opprettet! 🥳",
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt! 🤯 ",
        });
      }
      this.loading = false;
    },
    photoUploaded() {
      this.currentAchievement.ID = "";
    },
    async mountSuggestions() {
      this.mountingSuggestions = true;
      this.achievementSuggestions = await getAllAchievementSuggestions();
      this.achievement.title = "";
      this.achievement.achievedDescription = "";
      this.achievement.easterEgg = false;
      this.achievement.exp = 0;
      this.achievement.achievementType = "persistent";
      this.achievement.level = 1;
      this.achievement.lockedDescription = "";
      this.achievement.manualAssignment = true;
      this.challenge = false;
      this.challengeEndTime = "";
      this.mountingSuggestions = false;
    },
    chooseAchievement(achievement: Learnlink.Achievement.default) {
      this.achievement = { ...achievement };
      this.achievement.draft = false;
    },
  },
});
