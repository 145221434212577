

















import AchievementCard from "@/components/achievements/AchievementCard.vue";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "AchievementList",
  components: { AchievementCard },
  props: {
    achievements: {
      type: Array as PropType<Learnlink.Achievement.default[]>,
      required: true,
    }
  }
});
