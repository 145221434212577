

























import { uploadAchievementPhoto } from "@/api/achievement-service";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import Vue, { PropType } from "vue";

interface HTMLInputEvent extends Event {
  dataTransfer: HTMLInputElement & DataTransfer;
  target: HTMLInputElement & EventTarget;
}

export default Vue.extend({
  name: "AchievementPhotoUploader",
  props: {
    achievementID: {
      type: String as PropType<string>,
      required: true,
    },
    minimal: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleFileInput(e: HTMLInputEvent) {
      const fieldName = e.target.name;
      const fileList = e.target.files;
      const formData = new FormData();

      if (!fileList || !fileList.length) return;

      Array
        .from(Array(fileList.length).keys())
        .forEach(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

      this.save(formData);
    },
    async save(formData: FormData) {
      this.loading = true;
      try {
        await uploadAchievementPhoto(this.achievementID, formData);
        this.$emit("success");
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Bildet ble lastet opp! 🥳",
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt! 🤯",
        });
      }
      this.loading = false;
    }
  },
});
