import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/achievements";

export async function createAchievement(achievement: Learnlink.Achievement.Create): Promise<Learnlink.Achievement.default> {
  const achievementRes = await axios.post<Learnlink.Achievement.default>(
    `${ENDPOINT}/`,
    achievement,
  );

  return achievementRes.data;
}

export const uploadAchievementPhoto = async(achievementID: string, formData: FormData): Promise<string> => {
  const urlRes = await axios.post<string>(
    `${ENDPOINT}/uploadAchievementPhoto/${achievementID}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return urlRes.data;
};

export async function getAllAchievements(): Promise<Learnlink.Achievement.default[]> {
  const achievementSuggestionRes = await axios.get<Learnlink.Achievement.default[]>(
    `${ENDPOINT}/allAchievements`
  );

  return achievementSuggestionRes.data;
}

export async function getAllAchievementSuggestions(): Promise<Learnlink.Achievement.default[]> {
  const achievementSuggestionRes = await axios.get<Learnlink.Achievement.default[]>(
    `${ENDPOINT}/allAchievementSuggestions`
  );

  return achievementSuggestionRes.data;
}

export async function deleteAchievement(achievementID: string): Promise<void> {
  await axios.delete<void>(
    `${ENDPOINT}/${achievementID}`
  );
}
