import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2"},[_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[(!_vm.achievement.achievedImageURL && _vm.isTech && !_vm.lockedPhoto)?_c('achievement-photo-uploader',{attrs:{"minimal":"","achievement-i-d":_vm.achievement.ID},on:{"success":function($event){return _vm.$emit('success')}}}):_c('achievement-photo',{attrs:{"url":_vm.achievement.achievedImageURL}})],1),_c(VCol,[_c(VRow,[_c('h3',[_vm._v(_vm._s(_vm.achievement.title))])]),_c(VRow,[_c('h5',[_vm._v(" Oppnådd: "+_vm._s(_vm.achievement.achievedDescription)+" ")])]),_c(VRow,[_c('h5',{staticStyle:{"color":"darkgray"}},[_vm._v(" Låst: "+_vm._s(_vm.achievement.lockedDescription)+" ")])]),_c(VRow,{attrs:{"justify":"end"}},[_c('h5',[_vm._v("Exp: "+_vm._s(_vm.achievement.exp))])])],1),(_vm.achievement.draft)?_c(VCol,{attrs:{"cols":"2"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","color":"red","icon":"","small":""},on:{"click":function($event){return _vm.deleteAchievement()}}},on),[_c(VIcon,[_vm._v("mdi-close-thick")])],1)]}}],null,false,225008062)},[_c('span',[_vm._v("Slett")])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }