










































import { getAllAchievements } from "@/api/achievement-service";
import AchievementList from "@/components/achievements/AchievementList.vue";
import CreateAchievement from "@/components/achievements/CreateAchievement.vue";
import { techBros } from "@/helpers/techBros";
import useAsyncData from "@/helpers/useAsyncData";
import store from "@/store/store";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Achievements",
  components: { AchievementList, CreateAchievement },
  metaInfo() {
    return {
      title: "Admin / Achievements",
    };
  },
  setup() {
    const { isLoading, data: achievements, refresh } = useAsyncData(() => getAllAchievements());
    const isTech = techBros.includes(store.state.account.email);
    return {
      achievements,
      isLoading,
      refresh,
      isTech,
    };
  },
});
