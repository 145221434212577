





























































import { deleteAchievement } from "@/api/achievement-service";
import AchievementPhoto from "@/components/achievements/AchievementPhoto.vue";
import AchievementPhotoUploader from "@/components/achievements/AchievementPhotoUploader.vue";
import { techBros } from "@/helpers/techBros";
import store from "@/store/store";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "AchievementCard",
  components: { AchievementPhotoUploader, AchievementPhoto },
  props: {
    achievement: {
      type: Object as PropType<Learnlink.Achievement.default>,
      required: true,
    },
    lockedPhoto: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isTech: techBros.includes(store.state.account.email),
    };
  },
  methods: {
    async deleteAchievement() {
      await deleteAchievement(this.achievement.ID);
      await store.dispatch("displayAlert", {
        color: "success",
        message: "Slettet! 🤧",
      });
      this.$emit("deleted");
    },
  },
});
