












import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AchievementPhoto",
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    achievementPhotoPath: function(): string {
      return this.url || "https://firebasestorage.googleapis.com/v0/b/learnlink-prod.appspot.com/o/public%2Flocekd.jpg?alt=media&token=a843b3bf-435d-4007-b53d-f78da1f4e778";
    }
  },
});
